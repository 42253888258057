import * as React from "react"
import { graphql } from "gatsby"
import Container from "../components/Container/Container"
import G from "../images/groenhout_g.png"
import Seo from "../components/Seo/Seo"
import SmallHeroNew from "../components/SmallHeroNew/SmallHeroNew"
import CtaButton from "../components/CtaButton/cta"
import Testimonial from "../components/Testimonials/Testimonials"
import GravityForm from "../components/GravityForm"
import { faArrowDown } from "@fortawesome/free-solid-svg-icons"

const Dienst = ({data}) => {
  const content = data.wpDienst.content
  const image  = data.wpDienst.featuredImage !== null ? data.wpDienst.featuredImage.node.mediaItemUrl : ``
  const dienst = data.wpDienst.diensten

  if(!data) return null
  
  return (
    <>
      <Seo title={data.wpDienst.seo.title} description={data.wpDienst.seo.metaDesc}></Seo>
      <SmallHeroNew title={data.wpDienst.diensten.titel} desc={data.wpDienst.diensten.subtitel} overlay={true} overlayImage={G} image={image !== undefined ? image : ''}></SmallHeroNew>
      <Container styles={`px-8 lg:px-0 my-8 lg:my-16`}>
        <div dangerouslySetInnerHTML={{__html: content}} className='lg:w-3/5 mx-auto mb-8 lg:mb-16 text-black leading-relaxed content'></div>
        <h2 className="text-center text-blue text-3xl mb-8 lg:mb-16">{dienst.stapHeadline}</h2>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-2 gap-12 lg:gap-16 mb-16 w-full lg:w-3/4 mx-auto">
            {dienst.plan?.map((p, i)=>{
              const step = i + 1
              return(
                <div>
                  <div className="bg-blue-lighter rounded-full w-16 h-16 relative flex items-center justify-center"><span className="text-2xl text-blue font-bold z-10">0{step}</span><img src={G} className='w-full h-full absolute object-contain -bottom-1/4 left-0 mb-0'></img></div>
                  <div className="mt-4">
                    <h3 className="text-base mb-3 text-black">{p.stapTitel}</h3>
                    <div className="mb-0 text-black text-md leading-relaxed dienst-content" dangerouslySetInnerHTML={{__html: unescape(p.stapTekst)}}></div>
                  </div>
                </div>
              )
            })}
        </div>
      </Container>
      <div className="bg-light py-8 lg:py-16">
        <Container style={``}>
          <div className="flex flex-col items-center px-8 lg:px-0">
            <h2 className="text-center text-3xl text-blue w-full lg:w-1/2 mx-auto">{dienst.ctaHeadlineBefore.ctaHeadlineBefore}<a className="text-blue underlineRed" href="#form">{dienst.ctaHeadlineBefore.ctaHeadlineUnderline}</a>{dienst.ctaHeadlineBefore.ctaHeadlineAfter}</h2>
            <p className="lg:w-3/5 text-center mx-auto mt-4 text-black">{dienst.ctaTekst}</p>
            <div className="inline-block mt-4"><CtaButton title={dienst.ctaKnopTekst} target={'#form'} icon={faArrowDown} hover={'down'}></CtaButton></div>
          </div>
        </Container>
      </div>
      <Testimonial></Testimonial>
      
        <Container>
              <div id="form" className="bg-light w-full lg:w-2/5 mx-auto p-16 lg:mb-16">
                <h2 className="text-blue text-3xl">{dienst.formTitle}</h2>
                <GravityForm id={dienst.gravityFormsId}></GravityForm>
              </div>
        </Container>

        <div className="text-content">
                <Container>
                    <div dangerouslySetInnerHTML={{ __html: dienst.content }} className="text-content-small"></div>
                </Container>
            </div>
      
    </>

  )
}

export default Dienst;

export const postQuery = graphql`
  query($id: Int!){
    wpDienst(databaseId: {eq: $id}){
      title
      content
      featuredImage {
        node {
          mediaItemUrl
        }
      }
      seo{
        metaDesc
        title
      }
      diensten {
        titel
        subtitel
        stapHeadline
        content
        plan {
          stapTitel
          stapTekst
        }
        formTitle
        gravityFormsId
        ctaTekst
        ctaKnopTekst
        ctaHeadlineBefore {
          ctaHeadlineBefore
          ctaHeadlineUnderline
          ctaHeadlineAfter
        }
      }
    }
  }
  `